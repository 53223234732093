import React from "react"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import OpeningTimes from "../../../components/opening-times"
import PageTitle from "../../../components/page-title"
import Paragraph from "../../../components/paragraph"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheOeffnungszeiten = () => {
  return (
    <Layout backdrop="oeffnungszeiten">
      <Seo
        title="Öffnungszeiten und Eintritt"
        description="Zu diesen Zeiten können Sie den Hölderlin∙turm besuchen"
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Leichte Sprache",
              link: "/leichte-sprache",
            },
            {
              title: "Besuch",
              link: "/leichte-sprache/besuch",
            },
            {
              title: "Öffnungszeiten",
              link: "/leichte-sprache/besuch/oeffnungszeiten",
            },
          ]}
        />
        <PageTitle>Öffnungszeiten und Eintritt</PageTitle>

        <Paragraph>
          Zu diesen Zeiten können Sie den Hölderlin∙turm besuchen:
        </Paragraph>

        <OpeningTimes
          size={[3, 4]}
          columnWidth={["144px", "200px"]}
          space={[2, 3]}
        />
        <Paragraph>
          Am Dienstag und Mittwoch ist der Hölderlin∙turm geschlossen. <br />
          Dann kann man ihn nicht besuchen.
        </Paragraph>

        <Paragraph>Der Eintritt in den Hölderlin∙turm kostet nichts.</Paragraph>
        <Paragraph>Sie müssen sich aber an der Kasse anmelden.</Paragraph>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheOeffnungszeiten
